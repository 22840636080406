const getClientData = function (type) {
    const clientData = {
        clientName: "Daniel",
        clientKey: "WWetXPHdDeLcBh^67xnNgW+7",
        uid: "VYV01",
        clientCompanyTaxNumber: "921 373 511 MVA",
        clientCompanyName: "Vyvo Bygg AS",
        clientAddress: "Hertug Skules Gate 15D, 0652 Oslo",
        clientDomain: "vyvobygg.no",
        clientEmail: "post@vyvobygg.no",
        clientPhone: "+4746390093",
        clientFancyPhone: '46 39 00 93',
        instagram: "vyvobygg",
        facebook: "vyvobygg",
    }
    return clientData[type];
}

export {getClientData};
