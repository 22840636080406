const siteContent = [
  {
    link: 'main-view',
    pictures: {video: 'timeline.mp4' , backgroundPositionX: '50%', backgroundPositionY: '50%'},
    title: 'Appens hovedvisning',
    description: [
    'Hovedvisningen lar deg enkelt navigere i dokumentopprettingsprosessen. Menyen er til venstre. På høyre side er det en liste over dokumenter presentert i form av en kalender eller liste. Du kan filtrere dokumenter basert på type tidsliste, tilbud, faktura.',
    'Du kan vise det valgte dokumentet i forhåndsvisningsvinduet til venstre ved å klikke én gang. Ved å klikke på nytt aktiverer du forhåndsvisningen i full skjerm med dokumentalternativer som utskrift til PDF, gjenta dokument, redigering og andre spesialiserte funksjoner for det valgte dokumentet.'
  ],
    caption: 'Appens hovedvisning',
  },
  {
    link: 'main-menu',
    pictures: {video: 'menu.mp4', backgroundPositionX: '0%', backgroundPositionY: '50%'},
    title: 'Hovedmenyoversikt',
    description: [
      'Søk – en funksjon som lar deg søke i ressurser for å finne en klient, et prosjekt eller et dokument.',
      'Husk timer - registrer arbeidstimer i minnet.',
      'Nytt dokument – legg til et nytt tilbud, tidsliste eller fakturadokument.',
      'Prosjekter – opprett et nytt og vis listen over aktive prosjekter',
      'Kunder – opprett en ny og se listen over aktive kunder',
      'Innstillinger – åpner innstillingsmenyen',
      'Logg ut – logg ut brukeren.',

    ],
    caption: 'Hovedmenyoversikt',
  },
  {
    link: 'nytt-dokument',
    pictures: {video: 'new_document.mp4', backgroundPositionX: '50%', backgroundPositionY: '50%'},
    title: 'Lag et nytt dokument',
    description: [
      'For å opprette et nytt dokument, velg nytt dokument fra menyen. Du kan velge mellom et timelistedokument, et tilbudsdokument og et fakturadokument.',
      'Den øvre linjen informerer om dokumenttype og nummer. Inneholder knapper: minimer dokument, lukk dokument. Den siste knappen bytter mellom veiviservisningen og dokumentvisningen.',
      'Du kan opprette 3 dokumenter samtidig - 1 tidsliste, 1 tilbud og 1 faktura. ',
    ],
    caption: 'Lag et nytt dokument',
  },
  {
    link: 'ny-faktura',
    pictures: {video: 'faktura.mp4', backgroundPositionX: '0%', backgroundPositionY: '60%'},
    title: 'Ny faktura',
    description: [
      'For å begynne å lage et nytt fakturadokument, velg nytt dokument -> faktura fra menyen.',
      'Veiviseren vil lede deg gjennom 5 trinn i prosessen:',
      '1. Fullføre kundedata',
      '2. Informasjon om prosjektet',
      '3. Utstedelsesdato og utløp av dokumentet',
      '4. Dokumentelementer',
      '5. Forhåndsvis og lagre det ferdige dokumentet.'
    ],
    caption: 'Lag en faktura',
  },
  {
    link: 'ny-tilbud',
    pictures: {video: 'tilbud.mp4', backgroundPositionX: '0%', backgroundPositionY: '60%'},
    title: 'Ny tilbud',
    description: [
      'For å begynne å lage et nytt tilbuddokument, velg nytt dokument -> tilbud fra menyen.',
      'Veiviseren vil lede deg gjennom 5 trinn i prosessen:',
      '1. Fullføre kundedata',
      '2. Informasjon om prosjektet',
      '3. Utstedelsesdato og utløp av dokumentet',
      '4. Dokumentelementer',
      '5. Forhåndsvis og lagre det ferdige dokumentet.'
    ],
    caption: 'Lag en tilbud',
  },
  {
    link: 'tilbud-faktura',
    pictures: {video: 'tilbud_to_faktura.mp4', backgroundPositionX: '0%', backgroundPositionY: '60%'},
    title: 'Faktura fra tilbudet',
    description: [
      'Du kan enkelt utstede en faktura basert på tilbudsdokumentet. Åpne det valgte tilbudsdokumentet, og velg deretter fakturaalternativet fra dokumentmenyen. Feltene som inngår i tilbudet blir automatisk lagt til i fakturadokumentet.',
    ],
    caption: 'Lage en faktura fra tilbudet',
  },
  {
    link: 'ny-timelist',
    pictures: {video: 'timelist.mp4', backgroundPositionX: '0%', backgroundPositionY: '60%'},
    title: 'Ny timelist',
    description: [
      'For å begynne å lage et nytt timelistdokument, velg nytt dokument -> timelist fra menyen.',
      'Veiviseren vil lede deg gjennom 5 trinn i prosessen:',
      '1. Fullføre kundedata',
      '2. Informasjon om prosjektet',
      '3. Ansattes navn',
      '4. Dokumentelementer',
      '5. Forhåndsvis og lagre det ferdige dokumentet.'
    ],
    caption: 'Lag en timelist',
  },
  {
    link: 'timelist-faktura',
    pictures: {video: 'timelist_to_faktura.mp4', backgroundPositionX: '0%', backgroundPositionY: '60%'},
    title: 'Faktura fra en tidliste',
    description: [
      'Du kan enkelt utstede en faktura basert på et tidslistedokument. Åpne det valgte tidslistedokumentet, og velg deretter fakturaalternativet fra dokumentmenyen. En vare vil automatisk legges til i fakturadokumentet, som beregner arbeidstiden fra timelisten til timeprisen som er lagret i innstillingene.',
    ],
    caption: 'Lage en faktura fra en tidsliste',
  },
  {
    link: 'huske-timer',
    pictures: {video: 'add_day.mp4', backgroundPositionX: '0%', backgroundPositionY: '60%'},
    title: 'Hvordan huske timer?',
    description: [
      'Ved systematisk bruk er funksjonen til hjelp ved registrering av arbeidsdager. De innsamlede timene kan enkelt importeres til en tidsliste senere.',
      'For å lagre timene du har jobbet, velg husk timer fra menyen.',
      'I det viste vinduet skriver du inn informasjon om valgt arbeidsdag',
      'angi datoen og angi om dagen regnes som ekstra',
      'angi inn- og utreisetid og lengden på pausen',
      'I beskrivelsesfeltet skriver du inn en kort beskrivelse av arbeidet og bekrefter om all informasjon er korrekt',
      'Når den er lagret riktig, kan dagen importeres til tidslistedokumentet'
    ],
    caption: 'Hvordan huske timer?',
  },
  {
    link: 'huske-timer',
    pictures: {video: 'add_day_to_timelist.mp4', backgroundPositionX: '0%', backgroundPositionY: '60%'},
    title: 'Importer dager til tidslisten',
    description: [
      'For å bruke tidligere lagrede timer, opprett et nytt tidslistedokument.',
      'Timerimportvinduet vises automatisk for dokumentelementet i veiviseren.',
      'Velg dagene du vil importere og bekreft.',
      'De valgte dagene er lagt til dokumentet.',
    ],
    caption: 'Importer dager til tidslisten',
  },
];
const kontakt = [
  'Ved å la oss stå som totalleverandør for ditt prosjekt, stiller vi med prosjektleder og bruker våre samarbeidspartnere aktivt i ditt prosjekt. Under ser du noen av fordelene ved å benytte oss som din totalleverandør for ditt prosjekt:',
  'Du har en part å forholde deg til. Vi tar totalansvaret.',
  'Du slipper å bruke tid på å hente inn tilbud fra flere forskjellige leverandører.',
  'Du får et komplett tilbud fra oss.',
  'Vi stiller med alle nødvendige håndverkstjenester som prosjektet trenger.',
  'Vi står for kontakten og koordineringen av alle håndverkerne.',
  'Kvalitet i arbeidet. Vi er ansvarlig for kvalitetssikring.',
  'Skulle det være klager på prosjektet, så er vi ansvarlig for å rydde opp i det.',
  'Vi bringer prosjektet fra start til mål.',
];

const contactForm = {
  kontaktMain: {
    no: 'Vi er alltid glade for å svare på spørsmålene dine.'
  },
  kontakt: {
      no: ['navn', 'e-post / tlf.', 'telefon', 'melding']
  },
  kontaktRules: {
       no: 'Jeg godtar behandlingen av mine personlige opplysninger gitt i dette forespørselsskjemaet av Vyvo Bygg AS for å kontakte deg og svare på det stilte spørsmålet.'
  },
  thankYouHeader: ['Takk for melding!', 'Vi kontakter deg snart.'],
} ;
const aboutUs = [
  'Bygg med kvalitet',
  'Vyvo Bygg AS er en totalleverandør bygg- og anleggstjenester.',
  'Vi påtar oss små og mellomstore byggeprosjekter for private kunder, borettslag og bedrifter. Vi kan bistå deg gjennom hele byggeprosessen. Fra planlegging og grunnarbeid til mål.',
  'Vi har kompetanse innen de fleste tjenester innen oppussing, rehabilitering og andre byggetjenester. Dette gjelder såvel utvendig som innvendig rehabilitering. Vi utfører også tjenester som muring, flislegging og snekker arbeider i tillegg til at vi samarbeider med rørlegger og elektriker.',                 
];

const imageGalleries = [
  {
    folderName: ['fliser'],
    title: ['', '', '', '',],
    description: ['', '', '', '',],
    photos: [
      [
				{file: 'vyvo_bygg_as_flislegging_01.jpg', fill: 110, x: 0, y: 50},
				{file: 'vyvo_bygg_as_flislegging_02.jpg', fill: 100, x: 50, y: 50},
				{file: 'vyvo_bygg_as_flislegging_03.jpg', fill: 100, x: 50, y: 50},
				{file: 'vyvo_bygg_as_flislegging_04.jpg', fill: 120, x: 70, y: 50},
      ],
    ],
  },
  {
    folderName: ['parkett'],
    title: ['', '', '', '',],
    description: ['', '', '', '',],
    photos: [
      [
        {file: 'vyvo_bygg_as_parkett_01.jpg', fill: 110, x: 0, y: 50},
        {file: 'vyvo_bygg_as_parkett_02.jpg', fill: 100, x: 50, y: 50},
        {file: 'vyvo_bygg_as_parkett_03.jpg', fill: 100, x: 50, y: 50},
        {file: 'vyvo_bygg_as_parkett_04.jpg', fill: 120, x: 70, y: 50},
      ],
    ],
  },   
];

const getSiteContent = function () {
  return siteContent;
}
const getAboutUs = function () {
  return aboutUs;
}
const getGallery = function (galleryNumber) {
  return imageGalleries[galleryNumber];
}

export {getSiteContent, getGallery, getAboutUs, contactForm};