import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import './App.css';
import ring from './ring.svg';
import logo from './Asset5.svg';
import ContactForm from './ContactForm';
import { getClientData } from "./DataModelEngine";
import { serverConnection } from "./serverRouteEngine";
import { contactForm } from "./siteContentEngine";

class ContactPanel extends Component {
    constructor () {
        super();
        this.state = {
            messageId: false,
            messageForm: {
                email: false,
                mobile: false,
                name: false,
                message: false,
                accept: false,
            }
        }
        this.saveValues = this.saveValues.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.clearMessageId = this.clearMessageId.bind(this);
        this.footprint = this.footprint.bind(this);
    } 
    footprint(event) {
        let inquiry = {};
        inquiry.view = event.target.getAttribute('name');;
        inquiry.uid = getClientData('uid');
        inquiry.client_key = getClientData('clientKey');
        serverConnection('footprint', 'post', 'json', inquiry);
    }
    
    saveValues(object) {
        this.setState({
            messageForm: {
                ...this.state.messageForm,
                ...object,
            }
        })
    }

    clearMessageId(){
        this.setState({
            messageId: false
        })
    }

    sendForm() {
        let inquiry = this.state.messageForm;
        inquiry.uid = getClientData('uid');
        inquiry.client_key = getClientData('clientKey');
        const json = serverConnection('sendContactForm', 'post', 'json', inquiry);
        this.setState(
            {
                messageId: true, 
                messageForm: {
                    email: false,
                    mobile: false,
                    name: false,
                    message: false,
                    accept: false,
                },
            }
        )
    }
  render (){ 
    const lang = {...contactForm};
    return (
            <Col id="kontakt" lg={{span: '6', order: 'first'}} sm={{span: '12', order: 'first`'}}>
                <div className='watermark' style={{backgroundImage: 'url('+logo+')'}}></div>
                <h1>Kontakt oss</h1>
                {!this.state.messageId && <ContactForm contactRules={lang.kontaktRules.no} contactForm={lang.kontakt.no} sendForm={this.sendForm} saveValues={this.saveValues} {...this.state.messageForm}/>}
                {this.state.messageId && <div>
                    <h1>{lang.thankYouHeader[0]}</h1>
                    <h3>{lang.thankYouHeader[1]}</h3>
                    <Button ref={(el) => { this.nextButton = el; }} style={{borderRadius: 0, visibility: 'visible'}} as={Col} variant='dark'  name='next' id='next' onClick={this.clearMessageId}>OK</Button>
                </div>
                }
                <Row className='justify-content-md-center'>
					<Col sm={12}>
					<h2>{lang.kontaktMain.no}</h2>
					</Col>
					<Col sm={12}lg={{offset:1, span:4}} style={{textAlign:'center'}}>
						<h2>{'Ring oss'}</h2>
						<h3><a name='phone' onClick={this.footprint} href={"tel:"+getClientData('clientPhone')}>{getClientData('clientFancyPhone')}</a></h3>					
					</Col>
					<Col sm={12}lg={4} style={{textAlign:'center'}}>
						<h2>{'Skriv oss en e-post'}</h2>
						<h3><a name='email' onClick={this.footprint} href={"mailto:"+getClientData('clientEmail')}>{getClientData('clientEmail')}</a></h3>   
					</Col>
				</Row>

            </Col>
    )}
}

export default ContactPanel;

