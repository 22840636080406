import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ImgsGallery from "./ImgsGallery";
import Preface from './Preface';

class MainContent extends Component {

  render (){      
    const siteContent = this.props.siteContent ? this.props.siteContent : [];
    return (
    <main>
        <Container fluid>
        {siteContent.length > 0 && siteContent.map((item, index)=>(    
        <Row key={index} id={item.link} className='content'>
            {<Col lg='6' sm='12' className='picture'>
                <div style={{backgroundImage: "url(/"+item.pictures.foto +")",
                backgroundPositionX: item.pictures.backgroundPositionX,
                backgroundPositionY: item.pictures.backgroundPositionY,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'}}>
                <video width="100%" height="100%" controls>
                    <source src={item.pictures.video} type="video/mp4"/>
                </video>
                </div>  
            </Col>}
            { <Col lg='6' sm='12'>
                    <div  className="description">
                        <div><h1>{item.caption}</h1></div>
                        {item.description.map((description, idx)=>(
                            <p key={idx}>{description}</p>
                        ))}
                    </div>
            </Col>}

        </Row>
        ))} 
        </Container>        
        <div id="kontakt" style={{height: '1px', width: '100%'}}></div><div id="om-oss" style={{height: '1px', width: '100%'}}></div>
    </main>
    )}
}

export default MainContent;