import React, { Component } from 'react';
import './popup.css'
import ring from './ring.svg';
import { getClientData } from './DataModelEngine';
import { serverConnection } from './serverRouteEngine';


class CallPopup extends Component {
    constructor () {
        super();
        this.state = {};
        this.footprint = this.footprint.bind(this);
    } 
    footprint(event) {
        let inquiry = {};
        inquiry.view = event.target.getAttribute('name');;
        inquiry.uid = getClientData('uid');
        inquiry.client_key = getClientData('clientKey');
        serverConnection('footprint', 'post', 'json', inquiry);
    }
	render() {
		return (
			<div className='call-popup'>
				<p className='call-popup-close-button' onClick={this.props.closeCallPopup}>X</p>
				<a name='ring' onClick={this.footprint} href={'tel:'+getClientData('clientPhone')}>
                    <div className='ring' style={
                        {
                            backgroundImage: 'url('+ring+')',
                            backgroundRepeat: 'no-repeat',
                            width: "70%",
                            height: "70%",
                            position: "absolute",
                            top: '15%',
                            left: '30%',
                            transform: 'rotateZ(-15deg)'
                        }
                    }></div>
                </a>

			</div>
		) 
	}
}

export default CallPopup;