import React, { Component } from 'react';


class Preface extends Component {
	render() {
		return (
		<div>
			<div className='preface'>
				<div>	
					
					<div style={{
					
						minHeight: 300,
						minWidth: 300,
						backgroundSize: 'cover'
					}}>
						<h3>Vyvo Bygg AS er et entreprenørfirma med base i Oslo. </h3>
						<h3>Vyvo Bygg AS har fokus på effektivitet, nøyaktighet, kvalitet i hvert oppdrag.</h3>
					</div>
				</div>
				<div>
					
					<div style={{
						
						minHeight: 300,
						minWidth: 300,
						backgroundSize: 'cover'
					}}>
						<h3>Vi utfører små og store oppdrag innen
							<ul>
								<li>
									tømrer-,
								</li>
								<li>
									betong-,
								</li>
								<li>
									flis-,
								</li>
								<li>
									malerarbeid,
								</li>
							</ul>
							og prosjektledelse for privat- og bedriftskunder.</h3>
					</div>
				</div>
				<div>		
					
					<div style={{						
						
						minHeight: 300,
						minWidth: 300,
						backgroundSize: 'cover'
					}}>
						<h3>Har du et oppdrag du ønsker utført?</h3>
						<h3>Kontakt oss for å utveksle ideer og løsninger rundt ditt prosjekt.</h3>
					</div>
				</div>
			</div>
	
		</div>
		) 
	}
}

export default Preface;