import React, { Component } from 'react';

class Partners extends Component {
	constructor () {
        super();
        this.state = {};
    } 
    
	render() {
        const partners = [
            {
                logo: 'bekkestuakjokkenstudio.jpg',
                name: 'Bekkestua Kjøkkenstudio AS',
                link: 'https://www.bekkestuaks.no/',
            },
            {
                logo: 'eliteelektro.png',
                name: 'Elite Elektro AS',
                link: 'https://eliteelektro.no/',
            },
            {
                logo: 'oslotakogbyggevarer.jpeg',
                name: 'Oslo Tak og Byggevarer',
                link: 'https://oslobyggevarer.no',
            },
            {
                logo: 'rorverket.png',
                name: 'Rørverket AS',
                link: 'https://rorverket.no',
            },
            {
                logo: 'toreligaard.svg',
                name: 'Tore Ligaard AS',
                link: 'https://www.ligaard.net',
            },
            {
                logo: 'vinduer24.png',
                name: 'Vinduer24.no',
                link: 'https://www.vinduer24.no',
            },
            {
                logo: 'a-membran.jpg',
                name: 'A-Membran AS',
                link: 'https://www.amembran.no/',
            },
            {
                logo: 'fortrinn.png',
                name: 'Fortrinn AS',
                link: 'https://fortrinn.no/',
            },
            {
                logo: 'ostgulvsliping.svg',
                name: 'Østgulvsliping AS',
                link: 'https://ostgulvsliping.no/',
            },
            
        ];
		return (
		<div id='partners' className='partners'>
            <h3>Samarbeidspartnere</h3>
            {partners.map((partner, index)=>(
                <div id={'partner'+index}>
                    <a href={partner.link} alt={partner.name}/>
                    <img src={'./partners/'+partner.logo} alt={partner.name}/>
                </div>
            ))}
		</div>
		) 
	}
}

export default Partners;