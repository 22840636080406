import React, { Component } from "react";
import AboutUs from "./AboutUs";
import CallPopup from './CallPopup';
import Cookies from 'universal-cookie';
import CookiesPopup from './CookiesPopup';
import Footer from './Footer';
import MainContent from './MainContent';
import NavigationBar from './NavigationBar';
import TiltBackground from "./TiltBackground";

//graphic files
import backgroundImage from './background_image.jpg';
import backgroundImageSquare from './background_image_square.jpg';
import logo from './logo.svg';
import logotype from './Asset7.svg';

//App engine
import { serverConnection } from "./serverRouteEngine";
import { getClientData } from "./DataModelEngine";
import { getSiteContent } from "./siteContentEngine";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.js';
import $ from 'jquery';
import SloganHeader from "./SloganHeader";
import GodJulPopup from "./GodJul/GodJulPopup";
import SnowFlakes from "./GodJul/SnowFlakes";

class App extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  handshake() {
    const inquiry = {uid: getClientData('uid')};
    const json = serverConnection('handshake', 'post', 'json', inquiry);
    if (json && json.length > 0)
    {
      this.setState(
        {
          activatePage: json.handshake
        }
      )
    }
  }

  componentDidMount() {
    var x = 0;
    this.handshake();
    $(document).scroll(function () {
      var y = $(this).scrollTop();
      y = y+500;
      x = x > y ? x : y;

      $('.description, .picture, .gallery').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });

      $('.partners').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });

      $('.about-us').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).fadeIn();
            $('.kontakt').fadeIn();
        } else {
            $('.kontakt').fadeOut();
            $(this).fadeOut();
        }
      });

      $('.preface').each(function () {
        var t = $(this).parent().offset().top;
        if (x > t) {
            $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });     
    });
  }

  render (){
    const siteContent = getSiteContent();
    const cookies = new Cookies();
    const cookiesAccepted = cookies.get('cookies-accepted') ? cookies.get('cookies-accepted') : false;
    return (
      <div 
      className="App" 
      style={{display: this.state.activatePage === false ? 'none' : 'inherit'}}
      >
        
{
       // <GodJulPopup/>
       // <SnowFlakes/>
}
        {!cookiesAccepted && <CookiesPopup/>}
        <nav id='navigation-menu'>
          <NavigationBar siteContent={siteContent}/>
        </nav>
         <MainContent siteContent={siteContent}/>
        <footer>

          {//<Footer/>
          }
        </footer>
      </div>
    );
  }
}

export default App;
