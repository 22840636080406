import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import insta from './instagram.svg';
import fb from './facebook.svg';
import ContactPanel from './ContactPanel';
import { getAboutUs } from './siteContentEngine';
import { getClientData } from './DataModelEngine';
import { serverConnection } from './serverRouteEngine';
import Partners from './Partners';

class AboutUs extends Component {
	constructor () {
        super();
        this.state = {};
        this.footprint = this.footprint.bind(this);
    } 
    footprint(event) {
        let inquiry = {};
        inquiry.view = event.target.getAttribute('name');;
        inquiry.uid = getClientData('uid');
        inquiry.client_key = getClientData('clientKey');
        serverConnection('footprint', 'post', 'json', inquiry);
    }
	render() {
        let aboutUs = getAboutUs();
        const header = aboutUs[0];
        aboutUs.shift();
		return (
		<Container fluid>
			<Row className='about-us'>
				<Col lg='6' sm='12'>	
                    <h1>{header}</h1>
                    {aboutUs && aboutUs.length > 0 && aboutUs.map((textLine, index)=>(
                        <p key={index}>{textLine}</p>
                    ))}
                    <div className='gallery-insta' style={{backgroundImage: {fb}, backgroundRepeat: 'no-repeat',  backgroundSize: 'cover'}}>
					    <a name='facebook' onClick={this.footprint} href={'https://facebook.com/' + getClientData('facebook')}><img name='facebook' onClick={this.footprint} src={fb} style={{height: '77px'}} alt={getClientData('clientCompanyName') + " on Facebook"}/><p name='facebook' onClick={this.footprint}>follow us</p></a>
				    </div>	
                    <div className='gallery-insta' style={{backgroundImage: {insta}, backgroundRepeat: 'no-repeat',  backgroundSize: 'cover'}}>
					    <a name='instagram' onClick={this.footprint} href={'https://instagram.com/' + getClientData('instagram')}><img name='instagram' onClick={this.footprint} src={insta} style={{height: '77px'}} alt={getClientData('clientCompanyName') + " on Instagram"}/><p name='instagram' onClick={this.footprint}>{'@'+getClientData('instagram')}</p></a>
				    </div>		
                </Col>
                <ContactPanel/>
			</Row>
            <Partners/>
	
		</Container>
		) 
	}
}

export default AboutUs;