import React, { Component } from 'react';

class SloganHeader extends Component {
	constructor (props) {
		super(props);
		this.state = {
			followSharkContent: [],
			step: 0,
			loading: '/',
			fullSharkContent: ['K','v','a','l','i','t','e','t',' ','o','g',' ','p','r','e','s','i','s','j','o','n','\n\n','i',' ','a','l','l','e',' ','l','e','d','d']
		};
	};

	componentDidMount(){
		this.interval = setInterval(() => {
			if (this.state.fullSharkContent.length > 0)
				{
					let followSharkContent = this.state.followSharkContent.concat(this.state.fullSharkContent.shift());
					let step = this.state.step;
					step++;
					this.setState({ 
						step,
						followSharkContent,
					})
				}
			
			else  
			{
				let step = this.state.step;
				if (step<42)
				{
					step++;
					this.setState({
						step
					})
				}
				else if (step===42)
				{
				}

			}
	}, 100)}

	render() {
		return (
				<h2>{this.state.followSharkContent}</h2>
		) 
	}
}

export default SloganHeader;