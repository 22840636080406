import React, { Component } from "react";
import './App.css';

import logo from './jobbleder.svg';
import logotype from './Asset7.svg';
import { Navbar, Nav} from 'react-bootstrap';
import { getClientData } from "./DataModelEngine";
import { serverConnection } from "./serverRouteEngine";



class NavigationBar extends Component {
    constructor () {
        super();
        this.state = {};
        this.footprint = this.footprint.bind(this);
    } 
    footprint(event) {
        let inquiry = {};
        inquiry.view = event.target.getAttribute('name');;
        inquiry.uid = getClientData('uid');
        inquiry.client_key = getClientData('clientKey');
        serverConnection('footprint', 'post', 'json', inquiry);
    }
    

  render (){
    const basicMenu = [];
    let siteContent = this.props.siteContent ? this.props.siteContent : [];
    siteContent = siteContent.concat(basicMenu);
    return (
        <Navbar bg="light" variant="light" expand="lg" id="navigation-menu" collapseOnSelect>
            <Navbar.Toggle />
            <Navbar.Collapse >
                <Nav className="ml-auto">
                    {siteContent.length > 0 && siteContent.map((item, index)=>(
                        <Nav.Link name={item.link} key={index} href={'#'+item.link} onClick={this.footprint}>{item.title}</Nav.Link>                    
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )}
}

export default NavigationBar;