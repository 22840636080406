import React, { Component } from 'react';
import PicturesGallery from './PicturesGallery';
import { getGallery } from './siteContentEngine';


class ImgsGallery extends Component {
	render() {
		const galleryNumber = this.props.galleryNumber;
		const gallery = getGallery(galleryNumber);
		const folderName = gallery.folderName;
		return (
		<div id="imageGallery" className='gallery'>
			<div>
				{folderName.map((imageGallery, index)=>(
					<PicturesGallery key={index} description={gallery.description} folderName={imageGallery} title={gallery.title[index]} photos={gallery.photos[index]}/>
				))}		
			</div>		
		</div>
		) 
	}
}

export default ImgsGallery;