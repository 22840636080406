import React, { Component } from 'react';
import logo from './logo.svg';
import logotype from './Asset7.svg';
import fb from './facebook.svg';
import insta from './instagram.svg';
import { getClientData } from './DataModelEngine';
import { serverConnection } from './serverRouteEngine';

class Footer extends Component {
	constructor () {
        super();
        this.state = {};
        this.footprint = this.footprint.bind(this);
    } 
    footprint(event) {
        let inquiry = {};
        inquiry.view = event.target.getAttribute('name');;
        inquiry.uid = getClientData('uid');
        inquiry.client_key = getClientData('clientKey');
        serverConnection('footprint', 'post', 'json', inquiry);
    }
    
	render() {
		return (
		<div className='footer'>
			<div>
				<div className='footer-logo'>
					<img src={logo} alt={getClientData('clientCompanyName')}/>
					<img src={logotype} alt={getClientData('clientCompanyName')}/>
				</div>
				<p>{getClientData('clientCompanyTaxNumber')}</p>
				<p>{getClientData('clientAddress')}</p>
				<div className='footer-contact'>		
					<a name='ring' onClick={this.footprint} href={'tel:'+getClientData('clientPhone')}>{getClientData('clientFancyPhone')}</a>
					<a name='mail' onClick={this.footprint} href={"mailto:"+getClientData('clientEmail')}>{getClientData('clientEmail')}</a> 
				</div>
			</div>
			<div>	

			</div>
			<div>
			<div className='social-ico'>
				<div>
					<a name='facebook' onClick={this.footprint} href={'https://facebook.com/' + getClientData('facebook')}><img name='facebook' onClick={this.footprint} src={fb} alt={getClientData('clientCompanyName') + " on Facebook"}/><p name='facebook' onClick={this.footprint}>{getClientData('clientCompanyName')}</p></a>
				</div>
				<div>
					<a name='instagram' onClick={this.footprint} href={'https://instagram.com/' + getClientData('instagram')}><img name='instagram' onClick={this.footprint} src={insta} alt={getClientData('clientCompanyName') + " on Instagram"}/><p name='instagram' onClick={this.footprint}>{'@'+getClientData('clientCompanyName')}</p></a>
				</div>
				</div>
			</div>
			<div style={{width: "100%", height: '50px'}}>

			</div>
			<div style={{width: "100%", height: '70px', textAlign: 'center'}}>
				<p style={{width: "100%", height: '25px', textAlign: 'center'}}>Copyright 2023</p>
				<p style={{width: "100%", height: '25px', textAlign: 'center'}}><a name='laskowski' onClick={this.footprint} href='https://laskowski.no'>Webdesign Laskowski Creative</a></p>
			</div>
			<div style={{width: "100%", height: '50px'}}>

</div>
		</div>
		) 
	}
}

export default Footer;